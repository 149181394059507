import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";
import BanerMDD from "../../components/BanerMDD/BanerMDD";

const Niegolewskich = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Niegolewskich 14 - Marketing inwestycji deweloperskiej",
        href: slug,
        lang: "pl",
        featuredImage: require("../../assets/img/portfolio/niegolewskich_og.jpg"),
      }}
      header={{
        background:
          "linear-gradient( 225deg, #814B18, #9E6B3A, #BC8D5D, #D9AF81, #F7D3A8 )",
        icons: "#3F230B",
        navClass: "niegolewskich",
        ogImage: require("../../assets/img/portfolio/niegolewskich_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/niegolewskich14/",
      }}
    >
      <PortfolioHeader name="niegolewskich" height="135" />
      <section className="container-fluid niegolewskich_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Niegolewskich 14</h1>
              <ul>
                <li>Strona internetowa</li>
                <li>Kampania online</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="inner">
              <p>
                Nasz partner biznesowy zgłosił się do nas z projektem inwestycji
                deweloperskiej. Do projektu dołączyliśmy na etapie w którym
                strona internetowa oraz kampania kierowana na stronę internetową
                nie realizowały celów biznesowych Klienta.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="niegolewskich_section_scroll">
        <div className="scroll_wrapper scroll_wrapper--new">
          <span />
          <div className="img_wrapper">
            <img
              src={require("../../assets/img/portfolio/niegolewskich_entire_page.jpg")}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="niegolewskich_section_3">
        <div className="row no-gutters">
          <div className="col-md-6 offset-md-6">
            <h3>Strona internetowa</h3>
            <p>
              Strona internetowa wykonana przez inną agencję prezentowała się
              następująco:{" "}
              <a
                href="https://niegolewskich14.pl/"
                target="_blank"
                rel="noreferrer noindex nofollow"
              >
                niegolewskich14.pl
              </a>
              <br />
              Naszym zadaniem było pokazać ekskluzywne i prestiżowe apartamenty
              w Poznaniu. Zabytkowa kamienica została stworzona w stylu belle
              époque, dlatego chcieliśmy stworzyć projekt, który jest w stanie
              oddać klimat całego przedsięwzięcia.
              <br />
              Stworzona przez nas strona jest dostępna pod adresem{" "}
              <a
                href="https://lp.niegolewskich14.pl/"
                target="_blank"
                rel="noreferrer noindex nofollow"
              >
                lp.niegolewskich14.pl
              </a>
              .
            </p>
          </div>
        </div>
      </section>

      {/* <section className="container-fluid niegolewskich_section_4">
        <div className="gallery-row">
          <img
            src={require("../../assets/img/portfolio/niegolewskich-img-1.jpg")}
            alt=""
            className="img-fluid gallery-row__image image image--1"
          />
          <img
            src={require("../../assets/img/portfolio/niegolewskich-img-2.jpg")}
            alt=""
            className="img-fluid gallery-row__image image image--2"
          />
          <img
            src={require("../../assets/img/portfolio/niegolewskich-img-3.jpg")}
            alt=""
            className="img-fluid gallery-row__image image image--3"
          />
        </div>
      </section> */}

      <section className="container-fluid niegolewskich_section_5">
        <div className="row">
          <div className="col-md-12">
            <h3>Nasza realizacja</h3>
            <img
              src={require("../../assets/img/portfolio/niegolewskich-img-4.jpg")}
              alt="Nowa strona Niegolewskich14"
              className="img-fluid"
            />
          </div>
          <div className="col-md-9 offset-md-2">
            <img
              src={require("../../assets/img/portfolio/niegolewskich-img-5.jpg")}
              alt="Kalkulator mieszkań"
              className="img-fluid image--2"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid niegolewskich_section_6">
        <div className="row">
          <div className="col-md-10">
            <img
              src={require("../../assets/img/portfolio/niegolewskich-img-6.jpg")}
              alt=""
              className="img-fluid"
            />
          </div>
          <div className="col-12">
            <img
              src={require("../../assets/img/portfolio/niegolewskich-img-7.jpg")}
              alt=""
              className="img-fluid image--2"
            />
          </div>
        </div>
      </section>

      <section className="container-fluid niegolewskich_section_7">
        <div className="row align-items-center">
          <div className="col-md-5">
            <h3>Kampania online</h3>
            <p>
              Poza realizacją strony, odpowiadaliśmy za prowadzenie kampanii
              promocyjnej mającej na celu pozyskanie potencjalnych inwestorów.
              Przez okres czterech miesięcy współpracy z biurem sprzedaży,
              wspieraliśmy naszego klienta generując wartościowe leady.
            </p>
          </div>
          <div className="col-md-7">
            <img
              src={require("../../assets/img/portfolio/niegolewskich-img-8.jpg")}
              alt="Przykład banerów"
              className="img-fluid"
            />
          </div>
        </div>
      </section>

      <BanerMDD />
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Niegolewskich;
